import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Paper, TextField, Button, InputAdornment } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import './App.css';
import PostDetails from './PostDetails';
import './i18n';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import ResponsiveAppBar from './ResponsiveAppBar';
import AdComponent from './AdComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function App() {
  const { t, i18n } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  // Carregar posts a partir da API do backend
  useEffect(() => {
    fetch('https://feescrita-138417386089.us-central1.run.app/api/posts')
    //fetch('http://127.0.0.1:5000/api/posts')
.then((response) => {
            if (!response.ok) {
                throw new Error('Erro ao buscar posts do backend');
            }
            return response.json();
        })
        .then((data) => {
            // Converte os IDs para número caso venham como string
            const formattedPosts = data.map(post => ({
                ...post,
                id: Number(post.id), // Garante que o ID seja um número
            }));

            console.log("Posts recebidos do backend:", formattedPosts); // Debug para checar a ordem

            setPosts(formattedPosts);
            setFilteredPosts(formattedPosts);
        })
        .catch((error) => {
            console.error('Erro ao carregar os posts:', error);
        });
}, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = posts.filter(post => post.title.toLowerCase().includes(query));
    setFilteredPosts(filtered);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleAcceptCookies = () => {
    console.log('Usuário aceitou os cookies');
    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=G-4B431TTMT2`;
    gtagScript.async = true;
    document.body.appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-4B431TTMT2');

    const adsenseScript = document.createElement('script');
    adsenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    adsenseScript.async = true;
    adsenseScript.setAttribute('data-ad-client', 'ca-pub-3458489837498636');
    document.body.appendChild(adsenseScript);
  };

  return (
    <Router>
      <div className="App">
        <ResponsiveAppBar />

        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
          <TextField
            fullWidth
            label={t('search_placeholder')}
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: '20px' }}
          />
        </Container>

        <Routes>
          <Route
            exact
            path="/"
            element={
              <Container maxWidth="lg">
                <Grid container spacing={3}>
                {filteredPosts.map((post, index) => (
                   <Grid item xs={12} md={6} key={post.id} style={{ paddingBottom: '20px', margin: 0, width: '100%' }}>
                    <Paper style={{ padding: '15px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                      <div>
                        <Typography variant="h5" component="h2">
                          <Link to={`/posts/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {post.title}
                          </Link>
                        </Typography>
                        <Typography variant="body1" style={{
                          marginTop: '10px',
                          //height: '100px', // Altura fixa para igualar os textos
                          overflow: 'hidden', // Ocultar excesso de texto
                          textOverflow: 'ellipsis', // Adicionar reticências
                          display: '-webkit-box', // Para múltiplas linhas
                          WebkitLineClamp: 5, // Limitar para 5 linhas
                          WebkitBoxOrient: 'vertical',
                        }}>
                          {post.content}
                          <span style={{ color: 'blue', cursor: 'pointer' }}>
                            ... <Link
                                    to={`/posts/${post.id}`}
                                    style={{ textDecoration: 'none' }}
                                    sx={{
                                      color: '#1976D2', // Cor azul mais destacada
                                      fontWeight: 'bold',
                                      '&:hover': {
                                        textDecoration: 'underline', // Sublinhar no hover
                                        color: '#125699', // Cor mais escura no hover
                                      },
                                    }}
                                  >
                              Ler mais
                            </Link>
                          </span>
                        </Typography>
                      </div>
                      <img
                        src={`https://storage.googleapis.com/feescrita-posts-bucket/${post.id}.jpg`}
                        alt={post.title}
                        style={{ width: '100%', height: 'auto', marginTop: '10px', borderRadius: '8px' }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              </Container>
            }
          />
          <Route path="/posts/:id" element={<PostDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>

        <footer style={{ padding: '10px', backgroundColor: '#282c34', color: 'white', textAlign: 'center' }}>
          <Typography variant="body1">
            {t('copyright')}
          </Typography>
        </footer>

        <CookieConsent
          location="bottom"
          buttonText="Aceitar Cookies"
          onAccept={handleAcceptCookies}
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        >
          Este site utiliza cookies para melhorar sua experiência.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
