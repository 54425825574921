import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PostDetails = () => {
  const { id } = useParams();
  const postId = Number(id); // Converte para número e garante que está definido
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Fetching post with ID:", postId);

    fetch(`https://feescrita-138417386089.us-central1.run.app/api/posts/${postId}`)
    //fetch(`http://127.0.0.1:5000/api/posts/${postId}`)
      .then(response => {
        console.log("API Response Status:", response.status);
        return response.json();
      })
      .then(data => {
        console.log("API Response Data:", data);
        if (data.message !== "Post não encontrado") {
          setPost(data);
        } else {
          setPost(null);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao carregar o post:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!post) {
    return <Typography variant="h6">{t('post_not_found')}</Typography>;
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Paper style={{ padding: '20px' }}>
        {/* Título */}
        <Typography variant="h4" component="h1" gutterBottom>
          {post.title}
        </Typography>

        {/* Texto do post */}
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {post.content}
        </Typography>

        {/* Imagem exibida antes do botão de voltar */}
        <img
          src={`https://storage.googleapis.com/feescrita-posts-bucket/${postId}.jpg`}
          alt={post.title}
          style={{ width: '100%', height: 'auto', marginTop: '20px', borderRadius: '10px' }}
        />

        {/* Botão de Voltar após a imagem */}
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
          onClick={() => navigate(-1)}
        >
          {t('back_button')}
        </Button>
      </Paper>
    </Container>
  );
};

export default PostDetails;
